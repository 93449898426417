import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid, Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';
import { CardJobMinimal } from 'components/organisms';
import { Link } from 'gatsby';

const Jobs = props => {
  const { data, className, ...rest } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const {
    allContentfulResource: { nodes: resources },
  } = data;

  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Resources"
        subtitle="Keep track of what's happening with your tax."
        subtitleColor="textPrimary"
        ctaGroup={[
          <Button color="primary" size="large" variant="contained">
            <Link to="/contact-page" style={{ color: 'inherit' }}>
              Contact us
            </Link>
          </Button>,
        ]}
        data-aos="fade-up"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        {resources.map(item => (
          <Grid item xs={12} key={item.id}>
            <Link to={`/resources/${item.title.split(' ').join('-')}`}>
              <CardJobMinimal
                title={item.title}
                //subtitle={`${item.location} / ${item.type}`}
                showArrow={false}
                titleProps={{
                  variant: 'h6',
                  color: 'primary',
                }}
                subtitleProps={{
                  variant: 'subtitle1',
                }}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Jobs.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Jobs;
