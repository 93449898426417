/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import CareerListingMinimal from 'views/CareerListingMinimal';
import Main from 'layouts/Main';
import { graphql } from 'gatsby';

const CareerListingMinimalPage = ({ data }) => {
  return (
    <div className="container">
      <Main>
        <CareerListingMinimal data={data} />
      </Main>
    </div>
  );
};

export const query = graphql`
  {
    allContentfulResource(sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        id
        createdAt(locale: "")
      }
    }
  }
`;

export default CareerListingMinimalPage;
