import React from 'react';
import { SectionAlternate } from 'components/organisms';
import { Jobs } from './components';
import { Box } from '@material-ui/core';

const CareerListingMinimal = ({ data }) => (
  <Box mt={8}>
    <SectionAlternate>
      <Jobs data={data} />
    </SectionAlternate>
  </Box>
);

export default CareerListingMinimal;
